export const expoispTheme = {
  title: 'exposip',

  colors: {
    primary: '#14ABF4',
    primaryRgb: '20, 171, 244',
    primaryDark: '#245C9D',
    primaryDarkRgb: '36,92,157',
    primaryLight: '#14ABF4',
    primaryLightRgb: '20, 171, 244',

    secondary: '#001857',
    secondaryRgb: '0, 34, 87',

    success: '#25d366',
    delete: '#f44336',
    deleteRgb: '231,76,60',

    white: '#fff',
    black: '#121214',
  },
};
